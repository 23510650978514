export const homeObjOneCon = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'WANT TO GET IN CONTACT? BELOW ARE OUR DETAILS.',
    headline: 'CONTACT INFORMATION',
    description:
      'We provide shipping all over South Africa. If there are any issues, just issue a refund and we will process your request',
    buttonLabel: 'Shop Now',
    imgStart: '',
    img: 'images/logo3.jpg',
    alt: 'Credit Card',
    email: 'Manemedi@gmail.com',
    contactName: 'Thomas Thamane',
    contactNumber: '0713249659',
    Addressln1: '47 Fourteenth avenue',
    Addressln2: 'Northmead Benoni 1501',
    Addressln3: 'Gauteng',
  };