export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Your Medical and Surgical Supplier for the Healthcare Industry',
  headline: 'ABOUT US',
  description:
    "Manimed is a privately owned South African company which has recently been founded. Manimed Distributors' customer base is extensive, throughout Africa. Our main field of expertise lies in the supply of medical goods to institutions and patients as well as the supply of medicine to registered vendors only.",
  buttonLabel: 'Services',
  imgStart: 'start',
  img: 'images/aboutus.png',
  alt: 'Credit Card',
  showButton: true,
  url: '/services'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'PRODUCTS AND SERVICES',
  headline: 'MEDICAL DISTRIBUTION',
  description:
    "Manimed's product mix currently consists of a variety of different products for all medical and surgical needs, consisting of surgical consumables and specialty products which are used on a daily basis",
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/logo2.jpg',
  alt: 'Vault',
  showButton: false
  
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'service and support',
  headline: 'SUPPLIER OF CHOICE',
  description:
    "With attention to service and customer relations, you can be assured that Manimed Medical is your supplier of choice. We have a dedicated team to attend to all your needs with emphasis on service and support. All our products carry international certifications, to ensure quality standards across all furniture, equipment and medical consumables.Our aim is satisfying all our customers' needs, so if we don't have it, we will source it for you!",
  buttonLabel: 'Contact Us',
  imgStart: 'start',
  img: 'images/logo5.jpg',
  alt: 'Vault',
  showButton: true,
  url: '/contact-us'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
