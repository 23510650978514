import React from 'react';
import './App.css';
import Home from './components/Pages/HomePage/Home';
import Services from './components/Pages/Services/Services';
import Products from './components/Pages/Products/Products';
import SignUp from './components/Pages/SignUp/SignUp';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer/Footer';
import ContactUsMainDisp from './components/Pages/Contactus/ContactUsMainDisp';

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/services' element={<Services/>} />
          <Route path='/products' element={<Products/>} />
          <Route path='/contact-us' element={<ContactUsMainDisp/>} />
        </Routes>

        <Footer />
      </Router>

    </div>

  );
}

export default App;
