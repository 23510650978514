export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'SERVICES',
  headline: 'MANEMEDI SERVICES OFFERED',
  description:
    'Manemedi is a proudly South African company focusing on the sales and distribution of Medical Furniture and Hospital Equipment. The business was built on medical expertise, excellent client service and the pursuit of medical- and clinical innovation. Today, this desire to constantly improve and grow is an integral part of the Manemedi culture and the brand is now firmly established in the South African Medical supply chain.',
  buttonLabel: 'Get Started',
  imgStart: 'start',
  img: 'images/logo4.jpg',
  alt: 'Credit Card',
  showButton: false
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Medical Products',
  headline: 'Contact Us Today To Enquire',
  description:
    "With attention to service and customer relations, you can be assured that Manemedi is your supplier of choice. We have a dedicated team to attend to all your needs with emphasis on service and support. All our products carry international certifications, to ensure quality standards across all furniture, equipment and medical consumables. Our aim is satisfying all our customers' needs, so if we don't have it, we will source it for you!",
  buttonLabel: 'Contact Us',
  imgStart: '',
  img: 'images/logo1.jpg',
  alt: 'Vault',
  showButton: true,
  url: '/contact-us'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'EASY SETUP',
  headline: 'Super fast and simple onboarding process',
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/svg-7.svg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
